body.ro2 {
  font-weight: 500;
  font-family: $font;
  overflow: hidden;
  //background: #000;
  user-select:none;
  height:100%;
  font-family: $font;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.5;
  color: #32353c;
  font-size: 14px;
  padding: 0;
  margin: 0;
  position: fixed;
  width: 100%;
  .introjs-tooltip {
    padding: 20px;
    font-weight: 500;
    font-size: 13px;
  }
  .introjs-helperLayer {
    //box-shadow: 0 0 0 3px #E91E63;
    border: none;
    background: #fff;
  }
  a, button {
    font-weight: 400;
    text-transform: none;
  }
}

a, button {
  color: inherit;
  text-decoration: none;
  &:active, &:focus, &:hover{
    outline: none;
    text-decoration: none;
  }
}

label{
  padding-right: 10px;
}

.introjs-showElement{
  .ro-switch--label {
    color: #000;
  }
}
#chase-map{
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  .ol-overlaycontainer-stopevent{
    pointer-events: none;
  }
}
#app{
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: 300;
}

strong,b{
  font-weight: 500;
}

h3 {
  font-size: 20px;
  padding: 0;
  margin: 0;
  color: #fff;
}

.highcharts-tooltip .tooltip-inner {
  background: rgba(0,0,0,0.85);
  margin: -7px;
  padding: 10px;
}
