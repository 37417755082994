
@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,500,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Rubik:300,400,500,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,500,600,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Inconsolata:400,700&display=swap&subset=latin-ext');
@import url('https://fonts.googleapis.com/css?family=Quicksand:300,400,500,600,700&display=swap&subset=latin-ext');
// font-family: 'Source Sans Pro', sans-serif;
// font-family: 'Red Hat Text', sans-serif;
// font-family: 'Nunito', sans-serif;

$imgpath: '@ro-images/';
$font: 'Poppins', sans-serif, Helvetica Neue, Arial,sans-serif;
$fontawesome: 'FontAwesome';
$page-width: 1380px;
$loader-opacity: 0.9;
$ro-stroke: #000 1px 0px 0px, #000 0.5403px 0.84147px 0px, #000 -0.41615px 0.9093px 0px, #000 -0.98999px 0.14112px 0px, #000 -0.65364px -0.7568px 0px, #000 0.28366px -0.95892px 0px, #000 0.96017px -0.27942px 0px;
